












































































































import { Component, Vue } from 'vue-property-decorator';
import { post } from '@/shared/requests';
import Longform from '@/components/Longform.vue';
import MultiChoice from '@/components/MultiChoice.vue';
import TrueFalse from '@/components/TrueFalse.vue';
import SelectOneChart from '@/components/charts/SelectOneChart.vue';
import SelectManyChart from '@/components/charts/SelectManyChart.vue';
import TrueFalseChart from '@/components/charts/TrueFalseChart.vue';
import copy from '@/shared/copy';
import CollapsablePromptHeader from '../components/CollapsablePromptHeader.vue';

type ReadOnlyPrompt = {
  id: string;
  question: string;
} & ({ type: 'longform' | 'truefalse' } | { type: 'selectone' | 'selectmany'; options: string[] });

type ReadOnlyResponse = {
  id: string;
  promptId: string;
  userId: string;
} & ({ text: string } | { selection?: string } | { selections: string[] } | { verdict: boolean });

@Component({
  components: {
    Longform,
    MultiChoice,
    TrueFalse,
    SelectOneChart,
    SelectManyChart,
    TrueFalseChart,
    CollapsablePromptHeader,
  },
})
export default class Responses extends Vue {
  loading = true;

  error = '';

  tab = 0;

  responses: ReadOnlyResponse[] = [];

  responsesByPrompt: Record<string, ReadOnlyResponse[]> = {};

  responsesByUser: Record<string, ReadOnlyResponse[]> = {};

  prompts: Record<string, ReadOnlyPrompt> = {};

  journal: {
    name: string;
    id: string;
    dueAt: number;
    promptOrder: string[];
  } = {
    name: '',
    id: '',
    dueAt: Date.now() / 1000,
    promptOrder: [],
  };

  course: {
    id: string;
    course: string;
    section: string;
    semester: string;
  } = {
    id: '',
    course: '',
    section: '',
    semester: '',
  };

  copy = copy;

  mounted() {
    this.fetchResponses();
  }

  async fetchResponses() {
    const { journalId, enrollmentId } = this.$route.params;

    const { success, data, error } = await post('/instructor/journals/sections/responses', {
      journalId,
      enrollmentId,
    });

    this.$set(this, 'loading', false);

    if (success && data) {
      this.$set(this, 'journal', data.journal);
      this.$set(this, 'course', data.course);
      this.$set(
        this,
        'prompts',
        (data.prompts as ReadOnlyPrompt[]).reduce((a, b) => {
          a[b.id] = b;
          return a;
        }, {} as Record<string, ReadOnlyPrompt>),
      );
      this.$set(this, 'responses', data.responses);
      this.$set(
        this,
        'responsesByPrompt',
        (data.responses as ReadOnlyResponse[]).reduce((a, b) => {
          if (a[b.promptId]) {
            a[b.promptId].push(b);
          } else {
            a[b.promptId] = [b];
          }
          return a;
        }, {} as Record<string, ReadOnlyResponse[]>),
      );
      this.$set(
        this,
        'responsesByUser',
        (data.responses as ReadOnlyResponse[]).reduce((a, b) => {
          if (a[b.userId]) {
            a[b.userId].push(b);
            a[b.userId].sort(
              (c, d) =>
                data.journal.promptOrder.indexOf(c.promptId) -
                data.journal.promptOrder.indexOf(d.promptId),
            );
          } else {
            a[b.userId] = [b];
          }
          return a;
        }, {} as Record<string, ReadOnlyResponse[]>),
      );
      this.$store.commit(
        'injectResponseSet',
        (data.responses as ReadOnlyResponse[]).reduce((a, b) => {
          a[b.id] = b;
          return a;
        }, {} as Record<string, ReadOnlyResponse>),
      );
    } else if (error) {
      console.error(error);
      this.$set(this, error, error.message);
    }
  }
}
