function copy(text = ''): void {
  try {
    const input = document.createElement('input');
    input.setAttribute('class', 'hidden');
    input.setAttribute('value', text);

    document.body.appendChild(input);

    input.setSelectionRange(0, text.length);
    input.select();
    document.execCommand('copy');

    document.body.removeChild(input);
  } catch (error) {
    // No-op
  }
}

export default copy;
