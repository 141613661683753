
















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class CollapsablePromptHeader extends Vue {
  @Prop(Number) promptId!: number;

  @Prop(String) question!: string;
}
